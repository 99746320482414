import React from 'react';
import { Button } from 'semantic-ui-react';


import './Launch.css';
import clientConfig from './client.json';
const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  description?: string = 'description';
  title?: string = 'title';
}

interface LaunchProps {
  Launch: () => void;
}

{/* <div className="loader-view-progress">
        <div className="loader-inner-view">
          <div className="loader-inner">
            <div className="loader-wrapper">
              <svg width={371} height={370} viewBox="0 0 371 370" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="201.173" className="active" cy="5.7247" r={5} transform="rotate(5.14284 201.173 5.7247)" fill="white" fillOpacity="0.2" />
                <circle cx="217.178" className="active" cy="7.89263" r={5} transform="rotate(10.2857 217.178 7.89263)" fill="white" fillOpacity="0.2" />
                <circle cx="232.925" className="active" cy="11.4867" r={5} transform="rotate(15.4286 232.925 11.4867)" fill="white" fillOpacity="0.2" />
                <circle cx="248.286" className="active" cy="16.4777" r={5} transform="rotate(20.5715 248.286 16.4777)" fill="white" fillOpacity="0.2" />
                <circle cx="263.137" className="active" cy="22.8256" r={5} transform="rotate(25.7143 263.137 22.8256)" fill="white" fillOpacity="0.2" />
                <circle cx="277.36" className="active" cy="30.4792" r={5} transform="rotate(30.8571 277.36 30.4792)" fill="white" fillOpacity="0.2" />
                <circle cx="290.839" className="active" cy="39.377" r={5} transform="rotate(36 290.839 39.377)" fill="white" fillOpacity="0.2" />
                <circle cx="303.467" className="active" cy="49.4471" r={5} transform="rotate(41.1429 303.467 49.4471)" fill="white" fillOpacity="0.2" />
                <circle cx="315.141" className="active" cy="60.6087" r={5} transform="rotate(46.2857 315.141 60.6087)" fill="white" fillOpacity="0.2" />
                <circle cx="325.768" className="active" cy="72.7718" r={5} transform="rotate(51.4286 325.768 72.7718)" fill="white" fillOpacity="0.2" />
                <circle cx="335.261" className="active" cy="85.8385" r={5} transform="rotate(56.5714 335.261 85.8385)" fill="white" fillOpacity="0.2" />
                <circle cx="343.545" className="active" cy="99.7036" r={5} transform="rotate(61.7143 343.545 99.7036)" fill="white" fillOpacity="0.2" />
                <circle cx="350.553" cy="114.256" r={5} transform="rotate(66.8571 350.553 114.256)" fill="white" fillOpacity="0.2" />
                <circle cx="356.228" cy="129.377" r={5} transform="rotate(72 356.228 129.377)" fill="white" fillOpacity="0.2" />
                <circle cx="360.525" cy="144.946" r={5} transform="rotate(77.1429 360.525 144.946)" fill="white" fillOpacity="0.2" />
                <circle cx="363.409" cy="160.838" r={5} transform="rotate(82.2857 363.409 160.838)" fill="white" fillOpacity="0.2" />
                <circle cx="364.857" cy="176.924" r={5} transform="rotate(87.4286 364.857 176.924)" fill="white" fillOpacity="0.2" />
                <circle cx="364.857" cy="193.076" r={5} transform="rotate(92.5714 364.857 193.076)" fill="white" fillOpacity="0.2" />
                <circle cx="363.409" cy="209.162" r={5} transform="rotate(97.7143 363.409 209.162)" fill="white" fillOpacity="0.2" />
                <circle cx="360.525" cy="225.054" r={5} transform="rotate(102.857 360.525 225.054)" fill="white" fillOpacity="0.2" />
                <circle cx="356.228" cy="240.623" r={5} transform="rotate(108 356.228 240.623)" fill="white" fillOpacity="0.2" />
                <circle cx="350.553" cy="255.745" r={5} transform="rotate(113.143 350.553 255.745)" fill="white" fillOpacity="0.2" />
                <circle cx="343.545" cy="270.296" r={5} transform="rotate(118.286 343.545 270.296)" fill="white" fillOpacity="0.2" />
                <circle cx="335.261" cy="284.161" r={5} transform="rotate(123.429 335.261 284.161)" fill="white" fillOpacity="0.2" />
                <circle cx="325.768" cy="297.228" r={5} transform="rotate(128.571 325.768 297.228)" fill="white" fillOpacity="0.2" />
                <circle cx="315.141" cy="309.391" r={5} transform="rotate(133.714 315.141 309.391)" fill="white" fillOpacity="0.2" />
                <circle cx="303.467" cy="320.553" r={5} transform="rotate(138.857 303.467 320.553)" fill="white" fillOpacity="0.2" />
                <circle cx="290.839" cy="330.623" r={5} transform="rotate(144 290.839 330.623)" fill="white" fillOpacity="0.2" />
                <circle cx="277.36" cy="339.521" r={5} transform="rotate(149.143 277.36 339.521)" fill="white" fillOpacity="0.2" />
                <circle cx="263.137" cy="347.174" r={5} transform="rotate(154.286 263.137 347.174)" fill="white" fillOpacity="0.2" />
                <circle cx="248.286" cy="353.522" r={5} transform="rotate(159.429 248.286 353.522)" fill="white" fillOpacity="0.2" />
                <circle cx="232.925" cy="358.513" r={5} transform="rotate(164.571 232.925 358.513)" fill="white" fillOpacity="0.2" />
                <circle cx="217.178" cy="362.107" r={5} transform="rotate(169.714 217.178 362.107)" fill="white" fillOpacity="0.2" />
                <circle cx="201.173" cy="364.275" r={5} transform="rotate(174.857 201.173 364.275)" fill="white" fillOpacity="0.2" />
                <circle cx="185.038" cy={365} r={5} transform="rotate(180 185.038 365)" fill="white" fillOpacity="0.2" />
                <circle cx="168.903" cy="364.275" r={5} transform="rotate(-174.857 168.903 364.275)" fill="white" fillOpacity="0.2" />
                <circle cx="152.898" cy="362.107" r={5} transform="rotate(-169.714 152.898 362.107)" fill="white" fillOpacity="0.2" />
                <circle cx="137.151" cy="358.513" r={5} transform="rotate(-164.571 137.151 358.513)" fill="white" fillOpacity="0.2" />
                <circle cx="121.791" cy="353.522" r={5} transform="rotate(-159.429 121.791 353.522)" fill="white" fillOpacity="0.2" />
                <circle cx="106.939" cy="347.174" r={5} transform="rotate(-154.286 106.939 347.174)" fill="white" fillOpacity="0.2" />
                <circle cx="92.7163" cy="339.521" r={5} transform="rotate(-149.143 92.7163 339.521)" fill="white" fillOpacity="0.2" />
                <circle cx="79.2366" cy="330.623" r={5} transform="rotate(-144 79.2366 330.623)" fill="white" fillOpacity="0.2" />
                <circle cx="66.6093" cy="320.553" r={5} transform="rotate(-138.857 66.6093 320.553)" fill="white" fillOpacity="0.2" />
                <circle cx="54.935" cy="309.391" r={5} transform="rotate(-133.714 54.935 309.391)" fill="white" fillOpacity="0.2" />
                <circle cx="44.3083" cy="297.228" r={5} transform="rotate(-128.571 44.3083 297.228)" fill="white" fillOpacity="0.2" />
                <circle cx="34.8151" cy="284.161" r={5} transform="rotate(-123.429 34.8151 284.161)" fill="white" fillOpacity="0.2" />
                <circle cx="26.5312" cy="270.296" r={5} transform="rotate(-118.286 26.5312 270.296)" fill="white" fillOpacity="0.2" />
                <circle cx="19.5231" cy="255.745" r={5} transform="rotate(-113.143 19.5231 255.745)" fill="white" fillOpacity="0.2" />
                <circle cx="13.8479" cy="240.623" r={5} transform="rotate(-108 13.8479 240.623)" fill="white" fillOpacity="0.2" />
                <circle cx="9.55085" cy="225.054" r={5} transform="rotate(-102.857 9.55085 225.054)" fill="white" fillOpacity="0.2" />
                <circle cx="6.66713" cy="209.162" r={5} transform="rotate(-97.7143 6.66713 209.162)" fill="white" fillOpacity="0.2" />
                <circle cx="5.21937" cy="193.076" r={5} transform="rotate(-92.5714 5.21937 193.076)" fill="white" fillOpacity="0.2" />
                <circle cx="5.21929" cy="176.924" r={5} transform="rotate(-87.4286 5.21929 176.924)" fill="white" fillOpacity="0.2" />
                <circle cx="6.66718" cy="160.838" r={5} transform="rotate(-82.2857 6.66718 160.838)" fill="white" fillOpacity="0.2" />
                <circle cx="9.55097" cy="144.946" r={5} transform="rotate(-77.1429 9.55097 144.946)" fill="white" fillOpacity="0.2" />
                <circle cx="13.848" cy="129.377" r={5} transform="rotate(-72 13.848 129.377)" fill="white" fillOpacity="0.2" />
                <circle cx="19.5232" cy="114.255" r={5} transform="rotate(-66.8571 19.5232 114.255)" fill="white" fillOpacity="0.2" />
                <circle cx="26.5311" cy="99.7037" r={5} transform="rotate(-61.7143 26.5311 99.7037)" fill="white" fillOpacity="0.2" />
                <circle cx="34.815" cy="85.8386" r={5} transform="rotate(-56.5714 34.815 85.8386)" fill="white" fillOpacity="0.2" />
                <circle cx="44.3083" cy="72.7719" r={5} transform="rotate(-51.4286 44.3083 72.7719)" fill="white" fillOpacity="0.2" />
                <circle cx="54.935" cy="60.6087" r={5} transform="rotate(-46.2857 54.935 60.6087)" fill="white" fillOpacity="0.2" />
                <circle cx="66.6093" cy="49.4471" r={5} transform="rotate(-41.1429 66.6093 49.4471)" fill="white" fillOpacity="0.2" />
                <circle cx="79.2367" cy="39.3769" r={5} transform="rotate(-36 79.2367 39.3769)" fill="white" fillOpacity="0.2" />
                <circle cx="92.7164" cy="30.4792" r={5} transform="rotate(-30.8571 92.7164 30.4792)" fill="white" fillOpacity="0.2" />
                <circle cx="106.939" cy="22.8257" r={5} transform="rotate(-25.7143 106.939 22.8257)" fill="white" fillOpacity="0.2" />
                <circle cx="121.791" cy="16.4778" r={5} transform="rotate(-20.5715 121.791 16.4778)" fill="white" fillOpacity="0.2" />
                <circle cx="137.151" cy="11.4867" r={5} transform="rotate(-15.4286 137.151 11.4867)" fill="white" fillOpacity="0.2" />
                <circle cx="152.898" cy="7.89259" r={5} transform="rotate(-10.2857 152.898 7.89259)" fill="white" fillOpacity="0.2" />
                <circle cx="168.903" cy="5.72467" r={5} transform="rotate(-5.14284 168.903 5.72467)" fill="white" fillOpacity="0.2" />
                <circle cx="185.038" cy={5} r={5} fill="white" fillOpacity="0.2" />
              </svg>
            </div>
            <div className="text-wrapper">
              <div className="title">20%</div>
            </div>
          </div>
          <div className="text-inner">Get ready for an amazing immersive experience</div>
        </div>
      </div> */}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
  localStorage.setItem("counter",'1');

  return (
    <>


      <div id="launchContainer">
        {/* <div>
          <h1>{client.description}</h1>
          <Button size="massive" color="blue" circular icon="play" onClick={() => props.Launch()}></Button>
        </div> */}

        <div className="loader-screen">
          <div className="loader-load">
            <Button size="massive" className="button-load" color="blue" circular icon="play" onClick={() => props.Launch()}></Button>
            <p>{client.description}</p>

          </div>
        </div>
        {/* <img alt="PureWeb Logo" src="/pureweb.svg" style={{ width: 100, position: 'absolute', bottom: 50, right: 10 }} /> */}
      </div>
    </>
  );
};
